import { Link, useStaticQuery, graphql } from 'gatsby'
import React, { useState, useEffect } from 'react'
import { LinkedinShareButton, FacebookShareButton, TwitterShareButton } from 'react-share'

import { MarkdownComponent, CustomLink, StrongComponent, ApplyForm, Arrow } from '@/components'
import SEO from '@/components/seo'
import { htmlToMarkdownConverter } from '@/helpers'
import { ArrowBackIconComponent, FacebookIconComponent, TwitterIconComponent, LinkedinIconComponent } from '@/icons'
import { JobsSidebar } from '@/partials/jobsSidebar'
import { Label, Heading, ButtonPrimmary, Image } from '@/uikit'

const windowGlobal = typeof window !== 'undefined' && window

interface Props {
  pageContext: {
    id: number
    title: string
    updated_at: Date
    location: string
    locale: string
    departments: string[]
    content: string
    gh_Id: string
    questions: object
  }
}

const JobOfferPage: React.FC<Props> = ({ pageContext }) => {
  const [currentURL, setCurrentUrl] = useState('')
  const [tab, setTab] = useState(0)
  const {
    title,
    content,
    updated_at,
    location: { name: locale },
    questions: formFields,
    gh_Id,
  } = pageContext || {}
  const convertedContent = htmlToMarkdownConverter(content)
  const location = windowGlobal ? windowGlobal.location : undefined

  useEffect(() => {
    if (location) {
      setCurrentUrl(location.href)
    }
  }, [location?.href])

  let jobs = []
  const {
    allGreenhouseJob: { allJobs },
    globalBenefits,
  } = useStaticQuery<GatsbyTypes.JobsQueryQuery>(graphql`
    query JobsQuery {
      allGreenhouseJob {
        allJobs: edges {
          node {
            title
            content
            absolute_url
            updated_at(fromNow: true)
            gh_Id
            location {
              name
            }
            departments {
              name
            }
          }
        }
      }
      globalBenefits: allMarkdownRemark(filter: { fileAbsolutePath: { regex: "//partials/benefits.md$/" } }) {
        nodes {
          frontmatter {
            partial_benefits_list {
              heading
              description
              alternative_icon_text
              icon {
                childImageSharp {
                  gatsbyImageData(placeholder: BLURRED, width: 48, quality: 100)
                }
              }
            }
          }
        }
      }
    }
  `)

  const benefits = globalBenefits?.nodes[0]?.frontmatter?.partial_benefits_list

  if (allJobs.length) {
    // exclude common seniority titles from search
    const simpleTitle = title.replace(/Senior|Junior|Staff|Lead/gi, '')
    jobs = allJobs.filter((job) => {
      return job.node.gh_Id !== gh_Id && job.node.title.toLowerCase().trim().includes(simpleTitle.toLowerCase().trim())
    })
    jobs = jobs.slice(0, 4)
    if (jobs.length < 4) {
      jobs = jobs.concat(allJobs.slice(0, 4 - jobs.length))
    }
  }

  return (
    <>
      <SEO title={title} meta={{ description: `Seeking ${title} in ${locale}.` }} />
      <main>
        <div className="px-7.5 md:px-95 lg:px-110 xl:px-175 flex justify-center">
          <div className="max-w-1090 w-full">
            <div>
              <Link to="/careers" className="group flex items-center h-10 mt-17">
                <ArrowBackIconComponent className="block md:hidden dark:text-text-dm md:w-10 md:h-10 w-6 h-6 md:mt-0 transform group-hover:-translate-x-2 duration-500 print:hidden" />
                <Label>
                  <span className="ml-5 md:ml-0 inline-block print:mt-10 print:ml-0">Join As</span>
                </Label>
              </Link>
              <div className="mb-7.5 relative">
                <Link
                  to="/careers"
                  className="absolute -left-15 top-4 hidden md:block transform hover:-translate-x-2 duration-500"
                >
                  <ArrowBackIconComponent />
                </Link>
                <div className="dark:text-gray-darker">
                  <Heading>{title}</Heading>
                </div>
              </div>
              <p className="mb-7.5 dark:text-text-dm font-inter font-light text-base not-italic">{updated_at}</p>
            </div>
            <div className="flex mb-7">
              <div
                id="apply"
                className={`${
                  !tab ? 'border-orange' : 'hover:border-orange-base-10 border-transparent'
                } border-b-2 mb-5 duration-300`}
              >
                <button onClick={() => setTab(0)} className="focus:outline-none text-sm dark:text-text-dm px-10 pb-7">
                  ROLE SUMMARY
                </button>
              </div>
              <div
                className={`${
                  tab ? 'border-orange' : 'hover:border-orange-base-10 border-transparent'
                } border-b-2 mb-5 duration-300`}
              >
                <button onClick={() => setTab(1)} className="focus:outline-none text-sm dark:text-text-dm px-10 pb-7">
                  APPLICATION
                </button>
              </div>
            </div>
            <div className="flex justify-between max-w-1090 md:pb-210">
              <div className="grid grid-rows-1 grid-rows-1 grid-cols-1 w-full md:pr-7.5">
                <div
                  className={`${
                    !tab ? '' : 'hidden'
                  } row-start-1 row-end-1 col-start-1 col-end-1 max-w-770 w-full md:p-10 md:pb-0 lg:p-20 lg:pb-0 pb-0 md:bg-white md:dark:bg-body-bg1-dm rounded-2xl`}
                >
                  <MarkdownComponent
                    className="animate-fade-in"
                    externalComponents={{
                      strong: {
                        component: StrongComponent,
                        props: {
                          className: 'font-ilisarniq',
                        },
                      },
                    }}
                  >
                    {convertedContent}
                  </MarkdownComponent>
                  <div>
                    <h2 className="dark:text-text-dm mt-24 mb-10">Benefits</h2>
                    <ul data-ani-stagger="50" className="md:grid semiXl:grid-cols-2 justify-flex-end md:gap-x-7 z-10">
                      {benefits &&
                        benefits.map((benefit, i) => {
                          return (
                            <li key={`Benefit_${i}`} className="flex justify-end">
                              <div className="relative mb-10 semiXl:mb-20 w-full">
                                {benefit.icon && (
                                  <div className={` absolute -top-1.7 w-7`}>
                                    <Image
                                      src={benefit.icon}
                                      alt={benefit.alternative_icon_text}
                                      width="30"
                                      height="30"
                                      className="ani-child ani-left"
                                    />
                                  </div>
                                )}
                                <div className="ml-5 pl-6">
                                  <div className="mb-2.5">
                                    <b className="dark:text-text-dm leading-medium mb-2.5">{benefit.heading}</b>
                                  </div>
                                  <p className="font-inter font-light text-lg text-text-70 dark:text-text-70-dm leading-semiBig">
                                    {benefit.description}
                                  </p>
                                </div>
                              </div>
                            </li>
                          )
                        })}
                    </ul>
                  </div>
                  <div className="mb-24 mt-10 md:block hidden">
                    <CustomLink
                      className="whitespace-nowrap max-w-200 min-w-0 mx-auto block"
                      url="#apply"
                      type="internal"
                    >
                      <ButtonPrimmary onClick={() => setTab(1)}>Apply now</ButtonPrimmary>
                    </CustomLink>
                  </div>
                  <div className="mt-7.5 border-t dark:border-t border-text-80 border-opacity-20 dark:border-white-clear dark:border-opacity-20 pt-5.4 pb-7.5 flex justify-between">
                    <p className="border-none bg-none font-ilisarniq text-opacity-80 text-text dark:text-white-clear text-xs font-semibold leading-14">
                      Share
                    </p>
                    <div className="flex w-22 justify-between">
                      <FacebookShareButton
                        url={currentURL}
                        quote={title}
                        className="transition-transform duration-500 hover:scale-125 transform"
                      >
                        <FacebookIconComponent />
                      </FacebookShareButton>
                      <TwitterShareButton
                        url={currentURL}
                        title={title}
                        className="transition-transform duration-500 hover:scale-125 transform"
                      >
                        <TwitterIconComponent />
                      </TwitterShareButton>
                      <LinkedinShareButton
                        url={currentURL}
                        title={title}
                        className="transition-transform duration-500 hover:scale-125 transform"
                      >
                        <LinkedinIconComponent />
                      </LinkedinShareButton>
                    </div>
                  </div>
                </div>
                <div
                  aria-hidden={!tab}
                  className={`${
                    tab ? '' : 'opacity-0 pointer-events-none'
                  } duration-300 row-start-1 row-end-1 col-start-1 col-end-1 max-w-770 w-full md:mr-7.5 transition-all`}
                >
                  <div className="animate-fade-in">
                    <ApplyForm id={gh_Id} formFields={formFields} />
                  </div>
                </div>
              </div>
              <div className="md:block hidden relative">
                <div className="sticky top-7.5 flex-col">
                  <div className="p-7.5 rounded-lg bg-noise bg-blue-lightest dark:bg-body-bg1-dm w-96 max-w-290">
                    <p className="font-inter font-semibold text-lg leading-semiBig text-text dark:text-gray-darker">
                      {title}
                    </p>
                    <p className="dark:text-text-70-dm">{locale}</p>
                    <div
                      className={`${!tab ? 'h-12' : 'h-0 scale-0 opacity-50'} transform duration-300 transition-all`}
                    >
                      <CustomLink
                        className="whitespace-nowrap max-w-150 min-w-0 mx-auto mt-6 block"
                        url="#apply"
                        type="internal"
                      >
                        <ButtonPrimmary onClick={() => setTab(1)}>Apply now</ButtonPrimmary>
                      </CustomLink>
                    </div>
                  </div>
                  <JobsSidebar tab={tab} />
                </div>
              </div>
            </div>
          </div>
          {!tab && (
            <div className="fixed bottom-0 md:hidden w-full bg-white dark:bg-body-bg1-dm pb-4 px-7.5 pt-4 z-50">
              <CustomLink url="#apply" type="internal">
                <div className="flex justify-center">
                  <ButtonPrimmary onClick={() => setTab(1)}>Apply now</ButtonPrimmary>
                </div>
              </CustomLink>
            </div>
          )}
        </div>
        {jobs.length && (
          <div className="px-7.5 md:px-95 lg:px-110 xl:px-175 mb-48 flex justify-center">
            <div className="max-w-1090 w-full">
              <h2 className="mb-10 text-32 dark:text-text-dm font-bold">Related Jobs</h2>
              <ul
                data-ani-threshold="0"
                className="ani-group md:grid md:grid-cols-2 md:gap-7.5 sxl:grid-cols-4 gap-4 w-full md:w-auto"
              >
                {jobs.map(({ node }, i) => (
                  <li key={i} className="ani-child ani-up flex-1 flex mb-7.5 w-full md:mb-0">
                    <Link
                      to={`/job/${node?.gh_Id}`}
                      className="transform-gpu group w-full flex flex-col font-light text-text-80 bg-white dark:bg-body-bg1-dm block px-7.5 py-7.5 rounded-lg duration-500 hover:shadow-lg dark:hover:bg-body-bg2-dm"
                    >
                      <div className="max-w-230 w-full mb-2">
                        <b className="max-w-230 w-full dark:text-white">{node.title}</b>
                      </div>
                      <p className="dark:text-white-bg-35 mb-15 font-inter text-base leading-small md:mb-8.5">
                        {node.updated_at}
                      </p>
                      <p className="dark:text-white-bg-35 mb-15 font-inter text-base leading-small md:mb-8.5">
                        {node.location?.name}
                      </p>
                      <span className="flex justify-between mt-auto block items-center dark:text-blue-light text-blue-dark">
                        <p className="text-lg leading-medium font-ilisarniq font-normal">Details</p>
                        <Arrow className="transition transform group-hover:translate-x-2" />
                      </span>
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )}
      </main>
    </>
  )
}

export default JobOfferPage
