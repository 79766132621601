import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'

export const JobsSidebar: React.FC = () => {
  const data = useStaticQuery<GatsbyTypes.JobsSidebarQueryQuery>(graphql`
    query JobsSidebarQuery {
      coreValues: allMarkdownRemark(filter: { fileAbsolutePath: { regex: "//partials/jobs-sidebar.md$/" } }) {
        edges {
          node {
            frontmatter {
              header
              values: core_values {
                icon {
                  extension
                  publicURL
                }
                alternative_icon_text
                heading
                description
              }
            }
          }
        }
      }
    }
  `)
  const values = data?.coreValues?.edges.length ? data.coreValues.edges[0].node.frontmatter?.values : null
  const header = data?.coreValues?.edges.length ? data.coreValues.edges[0].node.frontmatter?.header : null
  return (
    <div className="bg-noise flex flex-col p-7.5 rounded-lg bg-blue-lightest dark:bg-body-bg1-dm w-96 max-w-290 mt-7.5">
      <h2 className="font-ilisarniq dark:text-text-dm">{header}</h2>
      <ul className="flex max-w-1150 h-full justify-center md:justify-between flex-col md:items-stretch items-center w-full">
        {values &&
          values.map((coreValue, i) => (
            <li className="flex-1 flex justify-start items-start flex-row md:flex-col bg-noise pt-10" key={i}>
              <div className="mb-2 mt-0.5 md:mt-0 md:max-w-full max-w-6.5 w-full">
                <img src={coreValue?.icon?.publicURL} alt={coreValue?.alternative_icon_text} height="40" width="40" />
              </div>
              <div className="ml-8 md:ml-0">
                <div className="mb-2">
                  <b className="mb-4 md:mb-2.5 dark:text-text-dm">{coreValue?.heading}</b>
                </div>
                <p className="font-inter font-light text-lg text-text-70 dark:text-text-70-dm leading-150">
                  {coreValue?.description}
                </p>
              </div>
            </li>
          ))}
      </ul>
    </div>
  )
}
